<template>
  <v-dialog v-model="dialogStubs" persistent :max-width="$vuetify.breakpoint.xsOnly ? '100vw' : '50vw'">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('stubs') }} - NF {{ row.documentCode }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="closeModal()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
        <v-container>
          <v-data-table
            :headers="headers"
            :items="geItemstList"
            :items-per-page="10"
            :footer-props="{ itemsPerPageText: $t('itemsPerPage'), pageText: `{0}-{1} ${$t('of')} {2}` }"
            class="elevation-1"
            item-key="file_id"
          >
            <template v-slot:no-data>
              {{ $t('noDataAvailable') }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>
            <template v-slot:item.link="{ item }">
              <v-btn v-if="item.storage == 'Link'" :href="item.url" target="_blank" icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
              <v-btn v-else @click="openTempLink(item.id)" icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-6 px-8">
        <v-spacer />
        <v-btn color="secondary" @click="closeModal()">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <TLoading v-model="loading" />
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mixinAlert from '@/mixins/mixinAlert'
import axios from 'axios'
import utils from '@/utils'
import config from '@/config'
import Moment from 'moment'

export default {
  name: 'InvoiceStubsDialogComponent',
  mixins: [mixinAlert],
  props: {
    dialogStubs: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$i18n.t('date'),
          value: 'createdAt',
        },
        {
          text: this.$i18n.t('file'),
          value: 'fileName',
        },
        {
          text: this.$i18n.t('extension'),
          value: 'extension',
        },
        {
          text: this.$i18n.t('link'),
          value: 'link',
        },
      ],
    }
  },
  watch: {
    dialogStubs: {
      async handler(value) {
        if (value) {
          await this.getItems()
        }
      },
    },
  },
  methods: {
    formatDate(value) {
      return Moment(value, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm')
    },
    closeModal() {
      this.clearItemsList()
      this.loading = false
      this.$emit('update:dialogStubs', false)
    },
    openTempLink(id) {
      axios
        .get(`${config.BACKEND_URL}/file/url/${id}`, {
          ...utils.makeBackendRequestData(),
        })
        .then(({ data }) => {
          if (data.url) {
            window.open(data.url, '_blank')
          }
        })
        .catch((error) => {
          this.mixinAlertError(error)
        })
        .then(() => {
          this.$refs.file.value = ''
          this.loading = false
        })
    },
    async getItems() {
      this.loading = true

      try {
        const result = await this.setItemsDataToList({
          nfe: this.row.documentCode,
          cnpj: this.row.transportCompanyCode,
          order_code: this.row.shipmentCode,
        })
        this.loading = false

        if (result instanceof Error || (result.typeMessage && ['E', ''].includes(result.typeMessage))) {
          const message = result.message ? result.message : ''
          await this.mixinAlertError(message)
        }
      } catch (erro) {
        await this.mixinAlertError(
          'Ocorreu um erro ao buscar os canhotos. Informe a equipe técnica ou tente novamente.'
        )
      } finally {
        this.loading = false
      }
    },
    ...mapActions({
      setItemsDataToList(dispatch, payload) {
        return dispatch('fileStore/setDataToList', payload)
      },
      clearItemsList(dispatch) {
        return dispatch('fileStore/clearState')
      },
    }),
  },
  computed: {
    ...mapState({
      geItemstList(_, getters) {
        return getters['fileStore/getList']
      },
    }),
  },
}
</script>
