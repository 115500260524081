<template>
  <v-dialog v-model="dialogItems" persistent :max-width="$vuetify.breakpoint.xsOnly ? '100vw' : '50vw'">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('invoiceItems') }} - NF {{ row.documentCode }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="closeModal()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
        <v-container>
          <v-data-table
            :headers="headers"
            :items="geItemstList"
            :items-per-page="10"
            :footer-props="{ itemsPerPageText: $t('itemsPerPage'), pageText: `{0}-{1} ${$t('of')} {2}` }"
            class="elevation-1"
          >
            <template v-slot:no-data>
              {{ $t('noDataAvailable') }}
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-6 px-8">
        <v-spacer />
        <v-btn color="secondary" @click="closeModal()">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <TLoading v-model="loading" />
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mixinAlert from '@/mixins/mixinAlert'

export default {
  name: 'InvoiceItemsDialogComponent',
  mixins: [mixinAlert],
  props: {
    dialogItems: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$i18n.t('docNum'),
          value: 'documentCode',
        },
        {
          text: this.$i18n.t('materialCode'),
          value: 'materialCode',
        },
        {
          text: this.$i18n.t('materialDescription'),
          value: 'materialDescription',
        },
        {
          text: this.$i18n.t('amount'),
          value: 'amount',
        },
        {
          text: this.$i18n.t('unitOfMeasurement'),
          value: 'unitOfMeasurement',
        },
      ],
    }
  },
  watch: {
    dialogItems: {
      async handler(value) {
        if (value) {
          await this.getItems()
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.clearItemsList()
      this.loading = false
      this.$emit('update:dialogItems', false)
    },
    async getItems() {
      this.loading = true
      const result = await this.setItemsDataToList(this.row.code)
      this.loading = false

      if (result instanceof Error || (result.typeMessage && ['E', ''].includes(result.typeMessage))) {
        const message = result.message ? result.message : ''
        await this.mixinAlertError(message)
      }
    },
    ...mapActions({
      setItemsDataToList(dispatch, payload) {
        return dispatch('invoicesStore/setItemsDataToList', payload)
      },
      clearItemsList(dispatch) {
        return dispatch('invoicesStore/clearItemsList')
      },
    }),
  },
  computed: {
    ...mapState({
      geItemstList(_, getters) {
        return getters['invoicesStore/geItemstList']
      },
    }),
  },
}
</script>
