<template>
  <div>
    <v-menu offset-y class="actionMenu">
      <template v-slot:activator="{ on }">
        <v-btn icon class="actionMenuButton" v-on="on">
          <v-icon color="primary"> mdi-dots-vertical </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item class="dataTableEditButton" @click.stop="dialog = true" v-if="row.delivery != 'Sim'">
          <v-list-item-avatar size="20">
            <v-icon size="20"> mdi-form-select </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="text2 secondary300">
            {{ $t('registerOccurrence') }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="dataTableDeleteButton" @click="getDanfe()">
          <v-list-item-avatar size="20">
            <v-icon size="20"> mdi-table-arrow-down </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="text2 secondary300">Baixar DANFE</v-list-item-content>
        </v-list-item>

        <v-list-item class="dataTableDeleteButton" @click.stop="dialogItems = true">
          <v-list-item-avatar size="20">
            <v-icon size="20"> mdi-folder-table-outline </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="text2 secondary300">{{ $t('invoiceItems') }}</v-list-item-content>
        </v-list-item>

        <v-list-item class="dataTableDeleteButton" @click="openSelectWindow()">
          <v-list-item-avatar size="20">
            <v-icon size="20"> mdi-upload </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="text2 secondary300">{{ $t('uploadStub') }}</v-list-item-content>
          <input id="file" ref="file" style="display: none" type="file" @change="onFilePicked($event)" />
        </v-list-item>

        <v-list-item class="dataTableDeleteButton" @click.stop="dialogStubs = true">
          <v-list-item-avatar size="20">
            <v-icon size="20"> mdi-camera </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="text2 secondary300">{{ $t('seeStub') }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <TLoading v-model="loading" />
    <occurrence-registry :dialog.sync="dialog" :row="row" />
    <invoice-items-dialog-component :dialogItems.sync="dialogItems" :row="row" />
    <invoice-stubs-dialog-component :dialogStubs.sync="dialogStubs" :row="row" />
  </div>
</template>

<script>
import OccurrenceRegistry from './OccurrenceRegistry'
import InvoiceItemsDialogComponent from './InvoiceItemsDialogComponent'
import InvoiceStubsDialogComponent from './InvoiceStubsDialogComponent'
import axios from 'axios'
import utils from '@/utils'
import config from '@/config'
import mixinAlert from '@/mixins/mixinAlert'

export default {
  mixins: [mixinAlert],
  components: {
    OccurrenceRegistry,
    InvoiceItemsDialogComponent,
    InvoiceStubsDialogComponent,
  },
  props: {
    row: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dialog: false,
      dialogItems: false,
      dialogStubs: false,
      file: null,
      loading: false,
    }
  },
  methods: {
    getDanfe() {
      this.loading = true
      axios
        .post(
          `${config.DANFE_SERVICE_URL}`,
          {
            SPRAS: 'PT',
            DOCNUM: this.row.code,
          },
          {
            ...utils.makeDanfeServiceRequestData(),
          }
        )
        .then(({ data }) => {
          if (data.parts && data.parts.danfe) {
            var link = document.createElement('a')
            link.href = 'data:application/octet-stream;charset=utf-16le;base64,' + data.parts.danfe.content
            link.download = `Danfe-${this.row.code}.pdf`
            link.click()
          }
        })
        .catch((error) => {
          this.mixinAlertError(error)
        })
        .then(() => {
          this.$refs.file.value = ''
          this.loading = false
        })
    },
    openSelectWindow() {
      this.$refs.file.click()
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    async onFilePicked(e) {
      const { files } = e.target
      if (!files[0]) return
      const confirmation = await this.mixinAlertConfirm(this.$t('confirmFileUpload'))
      if (!confirmation) {
        this.$refs.file.value = ''
        return
      }

      this.loading = true

      let payload = {}
      await this.convertToBase64(files[0]).then((data) => {
        payload.base64String = data
        payload.nfe = this.row.documentCode
        payload.cte = this.row.transportationCode
        payload.cnpj = this.row.transportCompanyCode
        payload.fileName = files[0].name
      })

      axios
        .post(`${config.BACKEND_URL}/file`, payload, {
          ...utils.makeBackendRequestData(),
        })
        .then(() => {
          this.mixinAlertSuccess(this.$t('fileUploadStubConcluded'))
        })
        .catch((error) => {
          this.mixinAlertError(error.response.data.message)
        })
        .then(() => {
          this.$refs.file.value = ''
          this.loading = false
        })
    },
  },
}
</script>
