<script>
import ListView from '@/components/ListView'
import { mapActions, mapGetters } from 'vuex'
import ActionInvoicesMenuComponent from './components/ActionInvoicesMenuComponent'
import Moment from 'moment'

export default {
  extends: ListView,
  name: 'ViewListInvoices',
  data() {
    return {
      namePage: this.$i18n.t('invoicesList'),
      path: 'invoices',
      filterContent: () => import('./InvoicesFiltersComponent.vue'),
      headerContent: () => import('./InvoiceHeaderComponent.vue'),
      hideDefaultActions: true,
      addNew: false,
      enablePrinting: true,
      dataProperties: { path: 'invoices', namePage: this.$i18n.t('invoicesList') },
      headers: [
        {
          text: this.$i18n.t('issueDate'),
          align: 'start',
          sortable: true,
          value: 'issueDate',
          createFilter: true,
          filterType: 'date',
          width: '10%',
          component: 'DataTableDateOnlyColumn',
          sortRule: (a, b) => {
            return Moment(a, 'DD/MM/YYYY') > Moment(b, 'DD/MM/YYYY') ? 1 : -1
          },
        },
        {
          text: this.$i18n.t('invoice'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'documentCode',
          width: '10%',
          customFilter: () => import('./components/DataTableInvoicesCustomFilter.vue'),
        },
        {
          text: this.$i18n.t('transportationCode'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'transportationCode',
          width: '10%',
        },
        {
          text: this.$i18n.t('transportDocumentType'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'transportDocumentType',
          width: '10%',
        },
        {
          text: this.$i18n.t('incoterms'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'incoterms',
          width: '10%',
        },
        {
          text: this.$i18n.t('redispatch'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'redispatch',
          width: '10%',
          component: 'YesNoChipStatus',
          options: {
            transform(value) {
              return value == 'Sim' ? true : false
            },
          },
        },
        {
          text: this.$i18n.t('receiver'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'clientName',
        },
        {
          text: this.$i18n.t('invoicesIssuerCompanyCode'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'companyCode',
        },
        {
          text: this.$i18n.t('transportCompanyCode'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'transportCompanyCode',
        },
        {
          text: this.$i18n.t('carrier'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'transportName',
        },
        {
          text: this.$i18n.t('transportCity'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'transportCity',
        },
        {
          text: this.$i18n.t('schedulingDate'),
          align: 'start',
          sortable: true,
          createFilter: true,
          filterType: 'date',
          value: 'schedulingDate',
        },
        {
          text: this.$i18n.t('deliveryForecastDate'),
          align: 'start',
          sortable: true,
          createFilter: true,
          filterType: 'date',
          value: 'deliveryForecastDate',
        },
        {
          text: this.$i18n.t('businessUnit'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'businessUnit',
        },

        {
          text: this.$i18n.t('destination'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'city',
          component: 'DataTableTransformValueColumn',
          options: {
            transform(value, row) {
              return value + '/' + row.state
            },
          },
        },
        {
          text: this.$i18n.t('lastOccurrence'),
          align: 'start',
          sortable: true,
          createFilter: true,
          filterType: 'date',
          value: 'statusDate',
          component: 'DataTableTransformValueColumn',
          options: {
            transform(value) {
              const date = Moment(value, 'DD/MM/YYYY HH:mm')
              return date.isValid() ? date.format('DD/MM/YYYY HH:mm') : '-'
            },
          },
        },
        {
          text: this.$i18n.t('wasDelivered'),
          align: 'start',
          sortable: false,
          createFilter: true,
          value: 'delivery',
          width: '8%',
          component: 'YesNoChipStatus',
          options: {
            transform(value) {
              return value == 'Sim' ? true : false
            },
          },
        },
        {
          text: this.$i18n.t('actions'),
          value: null,
          sortable: false,
          component: ActionInvoicesMenuComponent,
          width: '8%',
        },
        {
          text: this.$i18n.t('sapCode'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'sapCode',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('invoicesStore', {
      getGoodList: 'getList',
    }),
  },
  methods: {
    ...mapActions('invoicesStore', ['setDataToList', 'setDefaultPath']),
    async getItemsList() {
      return true
    },
  },
}
</script>
