export default {
  computed: {
    requiredRule() {
      return (v) => {
        if (typeof v === 'boolean') {
          return true
        }
        return !!v || this.$t('requiredMessage')
      }
    },
    dateMustBeBiggerRule() {
      return (value) => {
        const currentDate = this.$moment(new Date(), this.dateFormatBr)
        const expectedDate = this.$moment(value, this.dateFormatBr)
        if (currentDate.diff(expectedDate, 'days') > 0) {
          return this.$t('dateMustBeEqualOrGreaterThanCurrent')
        }
        return true
      }
    },
    valueMustBeNumberHifen() {
      return (value) => {
        if (value) {
          return new RegExp('^[0-9.-]*$').test(value) || 'O valor informado dever possuir apenas números e hífen.'
        }
        return true
      }
    },
    dateLength() {
      return (value) => {
        if (value && value.length != 10) {
          return `O dado informado deve possuir 10 caracteres`
        }
        return true
      }
    },
  },
  methods: {
    maxLength(value, max) {
      if (value && value.length > max) {
        return `O tamanha máximo é ${max} caracteres`
      }
      return true
    },
    length(value, length) {
      if (value && value.length != length) {
        return `O dado informado deve possuir ${length} caracteres`
      }
      return true
    },
    dataIntervalMustBeLessThanDays(startTime, finishTime, days, format) {
      let fomatDate = format || 'YYYY-MM-DD'
      const initialDate = this.$moment(startTime, fomatDate)
      const finalDate = this.$moment(finishTime, fomatDate)
      const diffDays = finalDate.diff(initialDate, 'days')

      if (diffDays < 0) {
        return this.$t('finalDateMustBeBiggerThanInitialDate', { days: days })
      }

      if (days > 0 && diffDays > days) {
        return this.$t('dateIntervalMustBeLessOrEqualThan', { days: days })
      }
      return true
    },
  },
}
