export default [
  { value: 0, text: 'PROCESSO DE TRANSPORTE JÁ INICIADO', valueText: '0 - PROCESSO DE TRANSPORTE JÁ INICIADO' },
  { value: 1, text: 'ENTREGA REALIZADA NORMALMENTE', valueText: '1 - ENTREGA REALIZADA NORMALMENTE' },
  { value: 2, text: 'ENTREGA FORA DA DATA PROGRAMADA', valueText: '2 - ENTREGA FORA DA DATA PROGRAMADA' },
  { value: 3, text: 'RECUSA POR FALTA DE PEDIDO DE COMPRA', valueText: '3 - RECUSA POR FALTA DE PEDIDO DE COMPRA' },
  { value: 4, text: 'RECUSA POR PEDIDO DE COMPRA CANCELADO', valueText: '4 - RECUSA POR PEDIDO DE COMPRA CANCELADO' },
  {
    value: 5,
    text: 'FALTA DE ESPAÇO FÍSICO NO DEPÓSITO DO CLIENTE DESTINO',
    valueText: '5 - FALTA DE ESPAÇO FÍSICO NO DEPÓSITO DO CLIENTE DESTINO',
  },
  {
    value: 6,
    text: 'ENDEREÇO DO CLIENTE DESTINO NÃO LOCALIZADO',
    valueText: '6 - ENDEREÇO DO CLIENTE DESTINO NÃO LOCALIZADO',
  },
  { value: 7, text: 'DEVOLUÇÃO NÃO AUTORIZADA PELO CLIENTE', valueText: '7 - DEVOLUÇÃO NÃO AUTORIZADA PELO CLIENTE' },
  {
    value: 8,
    text: 'PREÇO MERCADORIA EM DESACORDO COM O PEDIDO COMPRA',
    valueText: '8 - PREÇO MERCADORIA EM DESACORDO COM O PEDIDO COMPRA',
  },
  {
    value: 9,
    text: 'MERCADORIA EM DESACORDO COM O PEDIDO COMPRA',
    valueText: '9 - MERCADORIA EM DESACORDO COM O PEDIDO COMPRA',
  },
  {
    value: 10,
    text: 'CLIENTE DESTINO SOMENTE RECEBE MERCADORIA COM FRETE PAGO',
    valueText: '10 - CLIENTE DESTINO SOMENTE RECEBE MERCADORIA COM FRETE PAGO',
  },
  {
    value: 11,
    text: 'RECUSA POR DEFICIÊNCIA EMBALAGEM MERCADORIA',
    valueText: '11 - RECUSA POR DEFICIÊNCIA EMBALAGEM MERCADORIA',
  },
  { value: 12, text: 'REDESPACHO NÃO INDICADO', valueText: '12 - REDESPACHO NÃO INDICADO' },
  {
    value: 13,
    text: 'TRANSPORTADORA NÃO ATENDE A CIDADE DO CLIENTE DESTINO',
    valueText: '13 - TRANSPORTADORA NÃO ATENDE A CIDADE DO CLIENTE DESTINO',
  },
  { value: 14, text: 'MERCADORIA SINISTRADA', valueText: '14 - MERCADORIA SINISTRADA' },
  { value: 15, text: 'EMBALAGEM SINISTRADA', valueText: '15 - EMBALAGEM SINISTRADA' },
  { value: 16, text: 'PEDIDO DE COMPRAS EM DUPLICIDADE', valueText: '16 - PEDIDO DE COMPRAS EM DUPLICIDADE' },
  {
    value: 17,
    text: 'MERCADORIA FORA DA EMBALAGEM DE ATACADISTA',
    valueText: '17 - MERCADORIA FORA DA EMBALAGEM DE ATACADISTA',
  },
  { value: 18, text: 'MERCADORIAS TROCADAS', valueText: '18 - MERCADORIAS TROCADAS' },
  { value: 19, text: 'REENTREGA SOLICITADA PELO CLIENTE', valueText: '19 - REENTREGA SOLICITADA PELO CLIENTE' },
  {
    value: 20,
    text: 'ENTREGA PREJUDICADA POR HORÁRIO/FALTA DE TEMPO HÁBIL',
    valueText: '20 - ENTREGA PREJUDICADA POR HORÁRIO/FALTA DE TEMPO HÁBIL',
  },
  { value: 21, text: 'ESTABELECIMENTO FECHADO', valueText: '21 - ESTABELECIMENTO FECHADO' },
  { value: 22, text: 'REENTREGA SEM COBRANÇA DO CLIENTE', valueText: '22 - REENTREGA SEM COBRANÇA DO CLIENTE' },
  { value: 23, text: 'EXTRAVIO DE MERCADORIA EM TRÂNSITO', valueText: '23 - EXTRAVIO DE MERCADORIA EM TRÂNSITO' },
  {
    value: 24,
    text: 'MERCADORIA REENTREGUE AO CLIENTE DESTINO',
    valueText: '24 - MERCADORIA REENTREGUE AO CLIENTE DESTINO',
  },
  {
    value: 25,
    text: 'MERCADORIA DEVOLVIDA AO CLIENTE DE ORIGEM',
    valueText: '25 - MERCADORIA DEVOLVIDA AO CLIENTE DE ORIGEM',
  },
  { value: 26, text: 'NOTA FISCAL RETIDA PELA FISCALIZAÇÃO', valueText: '26 - NOTA FISCAL RETIDA PELA FISCALIZAÇÃO' },
  { value: 27, text: 'ROUBO DE CARGA', valueText: '27 - ROUBO DE CARGA' },
  { value: 28, text: 'MERCADORIA RETIDA ATÉ SEGUNDA ORDEM', valueText: '28 - MERCADORIA RETIDA ATÉ SEGUNDA ORDEM' },
  {
    value: 29,
    text: 'CLIENTE RETIRA MERCADORIA NA TRANSPORTADORA',
    valueText: '29 - CLIENTE RETIRA MERCADORIA NA TRANSPORTADORA',
  },
  {
    value: 30,
    text: 'PROBLEMA COM A DOCUMENTAÇÃO (NOTA FISCAL E/OU CTRC)',
    valueText: '30 - PROBLEMA COM A DOCUMENTAÇÃO (NOTA FISCAL E/OU CTRC)',
  },
  { value: 31, text: 'ENTREGA COM INDENIZAÇÃO EFETUADA', valueText: '31 - ENTREGA COM INDENIZAÇÃO EFETUADA' },
  { value: 32, text: 'FALTA COM SOLICITAÇÃO DE REPOSIÇÃO', valueText: '32 - FALTA COM SOLICITAÇÃO DE REPOSIÇÃO' },
  { value: 33, text: 'FALTA COM BUSCA/RECONFERÊNCIA', valueText: '33 - FALTA COM BUSCA/RECONFERÊNCIA' },
  { value: 34, text: 'CLIENTE FECHADO PARA BALANÇO', valueText: '34 - CLIENTE FECHADO PARA BALANÇO' },
  {
    value: 35,
    text: 'QUANTIDADE DE PRODUTO EM DESACORDO (NOTA FISCAL E/OU PEDIDO)',
    valueText: '35 - QUANTIDADE DE PRODUTO EM DESACORDO (NOTA FISCAL E/OU PEDIDO)',
  },
  {
    value: 36,
    text: 'EXTRAVIO DE DOCUMENTOS PELA CIA. AÉREA - CÓD. TRANSP. AÉREO',
    valueText: '36 - EXTRAVIO DE DOCUMENTOS PELA CIA. AÉREA - CÓD. TRANSP. AÉREO',
  },
  {
    value: 37,
    text: 'EXTRAVIO DE CARGA PELA CIA. AÉREA      – CÓD. TRANSP. AÉREO',
    valueText: '37 - EXTRAVIO DE CARGA PELA CIA. AÉREA      – CÓD. TRANSP. AÉREO',
  },
  {
    value: 38,
    text: 'AVARIA DE CARGA PELA CIA. AÉREA        – CÓD. TRANSP. AÉREO',
    valueText: '38 - AVARIA DE CARGA PELA CIA. AÉREA        – CÓD. TRANSP. AÉREO',
  },
  {
    value: 39,
    text: 'CORTE DE CARGA NA PISTA                – CÓD. TRANSP. AÉREO',
    valueText: '39 - CORTE DE CARGA NA PISTA                – CÓD. TRANSP. AÉREO',
  },
  {
    value: 40,
    text: 'AEROPORTO FECHADO NA ORIGEM            - CÓD. TRANSP. AÉREO',
    valueText: '40 - AEROPORTO FECHADO NA ORIGEM            - CÓD. TRANSP. AÉREO',
  },
  { value: 41, text: 'PEDIDO DE COMPRA INCOMPLETO', valueText: '41 - PEDIDO DE COMPRA INCOMPLETO' },
  {
    value: 42,
    text: 'NOTA FISCAL COM PRODUTOS DE SETORES DIFERENTES',
    valueText: '42 - NOTA FISCAL COM PRODUTOS DE SETORES DIFERENTES',
  },
  { value: 43, text: 'FERIADO LOCAL/NACIONAL', valueText: '43 - FERIADO LOCAL/NACIONAL' },
  { value: 44, text: 'EXCESSO DE VEÍCULOS', valueText: '44 - EXCESSO DE VEÍCULOS' },
  { value: 45, text: 'CLIENTE DESTINO ENCERROU ATIVIDADES', valueText: '45 - CLIENTE DESTINO ENCERROU ATIVIDADES' },
  { value: 46, text: 'RESPONSÁVEL DE RECEBIMENTO AUSENTE', valueText: '46 - RESPONSÁVEL DE RECEBIMENTO AUSENTE' },
  { value: 47, text: 'CLIENTE DESTINO EM GREVE', valueText: '47 - CLIENTE DESTINO EM GREVE' },
  {
    value: 48,
    text: 'AEROPORTO FECHADO NO DESTINO           - CÓD. TRANSP. AÉREO',
    valueText: '48 - AEROPORTO FECHADO NO DESTINO           - CÓD. TRANSP. AÉREO',
  },
  {
    value: 49,
    text: 'VÔO CANCELADO                          - CÓD. TRANSP. AÉREO',
    valueText: '49 - VÔO CANCELADO                          - CÓD. TRANSP. AÉREO',
  },
  { value: 50, text: 'GREVE NACIONAL (GREVE GERAL)', valueText: '50 - GREVE NACIONAL (GREVE GERAL)' },
  {
    value: 51,
    text: 'MERCADORIA VENCIDA (DATA DE VALIDADE EXPIRADA)',
    valueText: '51 - MERCADORIA VENCIDA (DATA DE VALIDADE EXPIRADA)',
  },
  {
    value: 52,
    text: 'MERCADORIA REDESPACHADA (ENTREGUE PARA REDESPACHO)',
    valueText: '52 - MERCADORIA REDESPACHADA (ENTREGUE PARA REDESPACHO)',
  },
  {
    value: 53,
    text: 'MERCADORIA NÃO FOI EMBARCADA, PERMANECENDO NA ORIGEM',
    valueText: '53 - MERCADORIA NÃO FOI EMBARCADA, PERMANECENDO NA ORIGEM',
  },
  {
    value: 54,
    text: 'MERCADORIA EMBARCADA SEM CONHECIMENTO/CONHECIMENTO NÃO EMBARCADO',
    valueText: '54 - MERCADORIA EMBARCADA SEM CONHECIMENTO/CONHECIMENTO NÃO EMBARCADO',
  },
  {
    value: 55,
    text: 'ENDEREÇO DE TRANSPORTADORA DE REDESPACHO NÃO LOCALIZADO/INFORMADO',
    valueText: '55 - ENDEREÇO DE TRANSPORTADORA DE REDESPACHO NÃO LOCALIZADO/INFORMADO',
  },
  {
    value: 56,
    text: 'CLIENTE NÃO ACEITA MERCADORIA COM PAGAMENTO DE REEMBOLSO',
    valueText: '56 - CLIENTE NÃO ACEITA MERCADORIA COM PAGAMENTO DE REEMBOLSO',
  },
  {
    value: 57,
    text: 'TRANSPORTADORA NÃO ATENDE A CIDADE DA TRANSPORTADORA DE REDESPACHO',
    valueText: '57 - TRANSPORTADORA NÃO ATENDE A CIDADE DA TRANSPORTADORA DE REDESPACHO',
  },
  { value: 58, text: 'QUEBRA DO VEICULO DE ENTREGA', valueText: '58 - QUEBRA DO VEICULO DE ENTREGA' },
  { value: 59, text: 'CLIENTE SEM VERBA PARA PAGAR O FRETE', valueText: '59 - CLIENTE SEM VERBA PARA PAGAR O FRETE' },
  { value: 60, text: 'ENDEREÇO DE ENTREGA ERRADO', valueText: '60 - ENDEREÇO DE ENTREGA ERRADO' },
  { value: 61, text: 'CLIENTE SEM VERBA PARA REEMBOLSO', valueText: '61 - CLIENTE SEM VERBA PARA REEMBOLSO' },
  {
    value: 62,
    text: 'RECUSA DA CARGA POR VALOR DE FRETE ERRADO',
    valueText: '62 - RECUSA DA CARGA POR VALOR DE FRETE ERRADO',
  },
  {
    value: 63,
    text: 'IDENTIFICAÇÃO DO CLIENTE NÃO INFORMADA/ENVIADA/INSUFICIENTE',
    valueText: '63 - IDENTIFICAÇÃO DO CLIENTE NÃO INFORMADA/ENVIADA/INSUFICIENTE',
  },
  { value: 64, text: 'CLIENTE NÃO IDENTIFICADO/CADASTRADO', valueText: '64 - CLIENTE NÃO IDENTIFICADO/CADASTRADO' },
  { value: 65, text: 'ENTRAR EM CONTATO COM O COMPRADOR', valueText: '65 - ENTRAR EM CONTATO COM O COMPRADOR' },
  { value: 66, text: 'TROCA NÃO DISPONÍVEL', valueText: '66 - TROCA NÃO DISPONÍVEL' },
  { value: 67, text: 'FINS ESTATÍSTICOS', valueText: '67 - FINS ESTATÍSTICOS' },
  { value: 68, text: 'DATA DE ENTREGA DIFERENTE DO PEDIDO', valueText: '68 - DATA DE ENTREGA DIFERENTE DO PEDIDO' },
  { value: 69, text: 'SUBSTITUIÇÃO TRIBUTÁRIA', valueText: '69 - SUBSTITUIÇÃO TRIBUTÁRIA' },
  { value: 70, text: 'SISTEMA FORA DO AR', valueText: '70 - SISTEMA FORA DO AR' },
  {
    value: 71,
    text: 'CLIENTE DESTINO NÃO RECEBE PEDIDO PARCIAL',
    valueText: '71 - CLIENTE DESTINO NÃO RECEBE PEDIDO PARCIAL',
  },
  {
    value: 72,
    text: 'CLIENTE DESTINO SÓ RECEBE PEDIDO PARCIAL',
    valueText: '72 - CLIENTE DESTINO SÓ RECEBE PEDIDO PARCIAL',
  },
  { value: 73, text: 'REDESPACHO SOMENTE COM FRETE PAGO', valueText: '73 - REDESPACHO SOMENTE COM FRETE PAGO' },
  {
    value: 74,
    text: 'FUNCIONÁRIO NÃO AUTORIZADO A RECEBER MERCADORIAS',
    valueText: '74 - FUNCIONÁRIO NÃO AUTORIZADO A RECEBER MERCADORIAS',
  },
  {
    value: 75,
    text: 'MERCADORIA EMBARCADA PARA ROTA INDEVIDA',
    valueText: '75 - MERCADORIA EMBARCADA PARA ROTA INDEVIDA',
  },
  { value: 76, text: 'ESTRADA/ENTRADA DE ACESSO INTERDITADA', valueText: '76 - ESTRADA/ENTRADA DE ACESSO INTERDITADA' },
  { value: 77, text: 'CLIENTE DESTINO MUDOU DE ENDEREÇO', valueText: '77 - CLIENTE DESTINO MUDOU DE ENDEREÇO' },
  { value: 78, text: 'AVARIA TOTAL', valueText: '78 - AVARIA TOTAL' },
  { value: 79, text: 'AVARIA PARCIAL', valueText: '79 - AVARIA PARCIAL' },
  { value: 80, text: 'EXTRAVIO TOTAL', valueText: '80 - EXTRAVIO TOTAL' },
  { value: 81, text: 'EXTRAVIO PARCIAL', valueText: '81 - EXTRAVIO PARCIAL' },
  { value: 82, text: 'SOBRA DE MERCADORIA SEM NOTA FISCAL', valueText: '82 - SOBRA DE MERCADORIA SEM NOTA FISCAL' },
  {
    value: 83,
    text: 'MERCADORIA EM PODER DA SUFRAMA PARA INTERNAÇÃO',
    valueText: '83 - MERCADORIA EM PODER DA SUFRAMA PARA INTERNAÇÃO',
  },
  { value: 84, text: 'MERCADORIA RETIRADA PARA CONFERÊNCIA', valueText: '84 - MERCADORIA RETIRADA PARA CONFERÊNCIA' },
  { value: 85, text: 'APREENSÃO FISCAL DA MERCADORIA', valueText: '85 - APREENSÃO FISCAL DA MERCADORIA' },
  { value: 86, text: 'EXCESSO DE CARGA/PESO', valueText: '86 - EXCESSO DE CARGA/PESO' },
  { value: 87, text: 'FÉRIAS COLETIVAS', valueText: '87 - FÉRIAS COLETIVAS' },
  { value: 88, text: 'RECUSADO, AGUARDANDO NEGOCIAÇÃO', valueText: '88 - RECUSADO, AGUARDANDO NEGOCIAÇÃO' },
  {
    value: 89,
    text: 'AGUARDANDO REFATURAMENTO DAS MERCADORIAS',
    valueText: '89 - AGUARDANDO REFATURAMENTO DAS MERCADORIAS',
  },
  { value: 90, text: 'RECUSADO PELO REDESPACHANTE', valueText: '90 - RECUSADO PELO REDESPACHANTE' },
  { value: 91, text: 'ENTREGA PROGRAMADA', valueText: '91 - ENTREGA PROGRAMADA' },
  { value: 92, text: 'PROBLEMAS FISCAIS', valueText: '92 - PROBLEMAS FISCAIS' },
  {
    value: 93,
    text: 'EM TRANSFERÊNCIA ENTRE FILIAIS DA TRANSPORTADORA',
    valueText: '93 - EM TRANSFERÊNCIA ENTRE FILIAIS DA TRANSPORTADORA',
  },
  {
    value: 94,
    text: 'MERCADORIA RECEBIDA PELA FILIAL DESTINO OU TRANSBORDO',
    valueText: '94 - MERCADORIA RECEBIDA PELA FILIAL DESTINO OU TRANSBORDO',
  },
  { value: 95, text: 'EM PROCESSO (EM ROTA) DE ENTREGA', valueText: '95 - EM PROCESSO (EM ROTA) DE ENTREGA' },
  {
    value: 96,
    text: 'CARGA SEM EMBALAGEM PRÓPRIA PARA TRANSP. AÉREO   - CÓD. TRANSP. AÉREO',
    valueText: '96 - CARGA SEM EMBALAGEM PRÓPRIA PARA TRANSP. AÉREO   - CÓD. TRANSP. AÉREO',
  },
  {
    value: 97,
    text: 'CARGA COM DIMENSÃO SUPERIOR AO PORÃO DA AERONAVE - CÓD. TRANSP. AÉREO',
    valueText: '97 - CARGA COM DIMENSÃO SUPERIOR AO PORÃO DA AERONAVE - CÓD. TRANSP. AÉREO',
  },
  { value: 98, text: 'PROCESSO FINALIZADO SEM ENTREGA', valueText: '98 - PROCESSO FINALIZADO SEM ENTREGA' },
  {
    value: 99,
    text: 'OUTROS TIPOS DE OCORRÊNCIAS NÃO ESPECIFICADOS ACIMA',
    valueText: '99 - OUTROS TIPOS DE OCORRÊNCIAS NÃO ESPECIFICADOS ACIMA',
  },
]
