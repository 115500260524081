<template>
  <v-dialog v-model="dialog" persistent :max-width="$vuetify.breakpoint.xsOnly ? '100vw' : '50vw'">
    <v-form ref="occurrenceForm" @submit.prevent="submit()">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('addOccurrence') }} - NF-e {{ row.documentCode }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeModal()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-0">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <input-date v-model="occurrence.date" :label="$t('date')" />
              </v-col>
              <v-col cols="12" sm="6">
                <input-time v-model="occurrence.time" :label="$t('time')" />
              </v-col>

              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="occurrence.statusCode"
                  :items="occurrenceItems"
                  item-text="valueText"
                  item-value="value"
                  :label="$t('occurrence')"
                  :rules="[requiredRule]"
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6">
                <input-date
                  v-model="occurrence.expectedDate"
                  :label="$t('expectedDate')"
                  :disabled="disableExpectedDate"
                  :rules="[dateMustBeBiggerRule, !disableExpectedDate ? requiredRule : true]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 px-8">
          <v-spacer />
          <v-btn color="secondary" @click="closeModal()">
            <v-icon left>mdi-close </v-icon>
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit">
            <v-icon left>mdi-content-save </v-icon>
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <TLoading v-model="loading" />
  </v-dialog>
</template>

<script>
import InputDate from '@/components/InputDate'
import InputTime from '@/components/InputTime'
import OccurrencesItems from '@/enums/occurrences'
import { mapActions } from 'vuex'
import mixinAlert from '@/mixins/mixinAlert'
import mixinRules from '@/mixins/mixinRules'

export default {
  name: 'OccurrenceRegistry',
  mixins: [mixinAlert, mixinRules],
  components: {
    InputDate,
    InputTime,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      occurrence: {
        date: '',
        expectedDate: '',
        time: '',
        statusCode: null,
      },
      dateFormatBr: 'DD/MM/YYYY',
      occurrenceItems: OccurrencesItems,
      loading: false,
    }
  },
  watch: {
    dialog: {
      handler(value) {
        if (value) {
          this.occurrence.date = this.$moment().format('YYYY/MM/DD')
          this.occurrence.time = this.$moment().format('HH:mm')
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.cleanupData()
      this.loading = false
      this.$emit('update:dialog', false)
    },
    async submit() {
      if (this.validateForm()) {
        this.loading = true
        const { series, companyCode, transportCompanyCode, documentCode } = this.row
        const invoiceCode = documentCode
        const occurrence = {
          series: series,
          companyCode,
          transportCompanyCode,
          statusDate: this.$moment(this.occurrence.date + ' ' + this.occurrence.time, 'YYYY/MM/DD HH:mm').format(
            'YYYY-MM-DDTHH:mm:ssZ'
          ),
          statusCode: String(this.occurrence.statusCode),
        }
        if (this.occurrence.expectedDate) {
          occurrence.expectedDate = this.$moment(this.occurrence.expectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        const result = await this.setOccurrence({ data: occurrence, invoiceCode })
        if (result instanceof Error || (result.typeMessage && ['E', ''].includes(result.typeMessage))) {
          this.closeModal()
          const message = result.message ? result.message : ''
          await this.mixinAlertError(message)
          return
        }
        this.closeModal()
        await this.mixinAlertSuccess(result.data[0].message)
      }
    },
    cleanupData() {
      const keys = Object.keys(this.occurrence)
      keys.forEach((key) => {
        this.occurrence[key] = ''
      })
    },
    validateForm() {
      return this.$refs.occurrenceForm.validate()
    },
    clearExpectedDate() {
      this.occurrence.expectedDate = ''
    },
    ...mapActions({
      setOccurrence(dispatch, payload) {
        return dispatch('occurrencesStore/setOccurrence', payload)
      },
    }),
  },
  computed: {
    disableExpectedDate() {
      if (this.occurrence.statusCode && this.occurrence.statusCode === 91) {
        return false
      }
      this.clearExpectedDate()
      return true
    },
  },
}
</script>
