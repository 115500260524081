<template>
  <v-text-field
    v-model="getValue"
    :outlined="outlined"
    :color="color"
    :rules="getRules"
    v-mask="'##:##'"
    :placeholder="placeholder"
    :clearable="clearable"
    v-bind="$attrs"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
export default {
  name: 'InputTime',
  computed: {
    getValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    getRules() {
      const newRulesArray = []
      newRulesArray.push(this.isValidHour())
      return newRulesArray.concat(this.rules)
    },
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '__:__',
    },
    rules: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    isValidHour() {
      return (value) => {
        if (!value) return true
        const timeValue = this.$moment(value, 'HH:mm')
        if (!timeValue.isValid()) {
          return this.$t('invalidHour')
        }
        return true
      }
    },
  },
}
</script>
